import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../../components/Hero'
import Article, { Text, Title } from '../../components/Article'

import ContactCard from '../../components/ContactCard'

import { IconList } from '../../components/Brandbook'

import Button from '../../components/Button'
import Link from '../../components/Link'
import Image from '../../components/Image'
import Section from '../../components/Section'

import illustrationsPreview from '../../assets/brandbook/images/illustrations_preview.svg'

export default function IconsAndIllustrations ({ data: { hero }, ...props }) {
  const icons = [
    'shipfront',
    'boatMedium',
    'truck',
    'port',
    'containers',
    'search',
    'check',
    'flip',
    'arrow',
    'calendar',
    'link',
    'like',
  ]

  return (
    <>
      <Hero {...hero} light title="Icons and illustrations" />

      <Article>
        <Text>
          We have our own custom icon and illustration language. The visual
          language of the icons and illustrations draws on the characteristics
          of our main font, <Link to="/brandbook/typography">Wigrum</Link>.
        </Text>

        <Title level="2">Icons</Title>

        <Text>
          Our icons are based om 2 pts stroke. They can easily scale but
          remember to adjust the stroke size accordingly.{' '}
        </Text>

        <IconList icons={icons} />
        <br />
        <Button
          primary
          to="https://drive.google.com/open?id=1U14l5ZoKOzjDU0ZzsebnB2-JFqssTTay"
        >
          Download (SVG)
        </Button>

        <Button
          primary
          to="https://drive.google.com/open?id=1U12RRzlF8xPf478Vm6MbJQtxhJDPt-rS"
        >
          Download (PNG)
        </Button>
        <Title level="2">Illustrations</Title>

        <Text>
          Our hand-crafted illustrations all represent different parts of the
          NCL business.{' '}
        </Text>

        <Image src={illustrationsPreview} />

        <Button
          primary
          to="https://drive.google.com/open?id=1UBs-X1YDoLF84t-gMmKc98RkaqCO7G9s"
        >
          Download (SVG)
        </Button>

        <Button
          primary
          to="https://drive.google.com/open?id=1U2Ezk8dlPEtNBFz8IMKVT-1uVJxhXyh6"
        >
          Download (PNG)
        </Button>
      </Article>

      <Section>
        <Title>Any questions?</Title>
        <ContactCard who="ada-jakobsen" />
      </Section>
    </>
  )
}

IconsAndIllustrations.propTypes = {
  data: PropTypes.object.isRequired,
}

IconsAndIllustrations.defaultProps = {
  brandbook: true,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/cargooperation/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
